import React, { useState, useEffect } from 'react'
import { object, array } from 'prop-types'
import { PrismicRichText } from '@prismicio/react'
import Select from 'react-select'

import { FilterCloseX } from './Icons'
import FilterRow from './FilterRow'
import { DROPDOWN_STYLES } from '../util/constants'
import CmeMediaLibraryItem from './ContentBlocks/CmeMediaLibraryItem'
import * as Styled from './styles/MediaLibrary.styles'
import { RegionContainer } from './styles/Utility.styles'
import CtaLink from './ContentBlocks/CtaLink'
import CmeSidebar from './ContentBlocks/CmeSidebar'
import PaginationControl from './PaginatonControl'
import { MediaLibrarySearchField } from './styles/FilterRow.styles'
import {
  getStorageString,
  setStorageString,
  removeStorageString,
  StorageKey,
  StorageType,
} from '../util/storage'
import WithLocation from '../util/WithLocation.jsx'

const ITEMS_PER_PAGE = 24

const getMediaCreditsOptions = credits => {
  const options = []
  credits.map(credit => {
    options.push({
      value: credit.node.data.credits,
      label: credit.node.data.credits,
    })
  })
  return options
}

const getMediaTypeOptions = types => {
  const options = []
  types.map(type => {
    options.push({ value: type.node.data.type, label: type.node.data.type })
  })
  return options
}

const getMediaTopicOptions = topics => {
  const options = []
  topics.map(topic => {
    options.push({ value: topic.node.data.topic, label: topic.node.data.topic })
  })
  return options
}

const MediaLibraryItemsGrid = ({
  creditsFilter,
  mediaFilter,
  topicFilter,
  searchInput,
  mediaItemsList,
  setFilteredItemsCount,
  firstItemForPage,
  lastItemForPage,
}) => {
  const filtered = mediaItemsList
    .filter(item => {
      const title = item.node.data.title?.text.toUpperCase()
      const body = item.node.data.body_text?.text.toUpperCase()
      return (
        title.includes(searchInput.toUpperCase()) ||
        body.includes(searchInput.toUpperCase())
      )
    })
    .filter(item => {
      if (!item.node.data.credits?.document?.data?.credits) {
        return false
      }
      return creditsFilter
        ? item.node.data.credits.document.data.credits === creditsFilter
        : true
    })
    .filter(item => {
      if (!item.node.data.media_type?.document?.data?.type) {
        return false
      }
      return mediaFilter
        ? item.node.data.media_type.document.data.type === mediaFilter
        : true
    })
    .filter(item => {
      if (!item.node.data.topic?.document?.data?.topic && topicFilter) {
        return false
      }
      return topicFilter
        ? item.node.data.topic.document.data.topic === topicFilter
        : true
    })

  useEffect(() => {
    setFilteredItemsCount(filtered.length)
  }, [filtered])

  return (
    <Styled.ItemsGridContainer>
      {filtered.slice(firstItemForPage, lastItemForPage).map(item => (
        <CmeMediaLibraryItem key={item.id} item={item} />
      ))}
    </Styled.ItemsGridContainer>
  )
}

const FilterIndicator = ({ filter, clearFilter, isSearch = false }) => (
  <>
    {filter && (
      <Styled.FilterIndicator>
        <Styled.FilterIndicatorText>
          {!isSearch
            ? filter
            : `${String.fromCharCode(8220)}${filter}${String.fromCharCode(
                8220
              )}`}
        </Styled.FilterIndicatorText>
        <Styled.CloseXContainer onClick={e => clearFilter(filter, e)}>
          <FilterCloseX />
        </Styled.CloseXContainer>
      </Styled.FilterIndicator>
    )}
  </>
)

const FilterIndicatorBar = ({
  creditsFilter,
  mediaFilter,
  topicFilter,
  searchInput,
  clearFilter,
}) => (
  <Styled.FilterIndicatorBar
    isEnabled={creditsFilter || mediaFilter || topicFilter || searchInput}
  >
    <Styled.ShowingResultsFor>Showing results for: </Styled.ShowingResultsFor>
    <FilterIndicator filter={creditsFilter} clearFilter={clearFilter} />
    <FilterIndicator filter={mediaFilter} clearFilter={clearFilter} />
    <FilterIndicator filter={topicFilter} clearFilter={clearFilter} />
    <FilterIndicator filter={searchInput} clearFilter={clearFilter} isSearch />
  </Styled.FilterIndicatorBar>
)

const CmeMediaLibrary = ({
  doc,
  credits,
  types,
  topics,
  search,
  mediaItemsList,
  paginationChangeAction,
}) => {
  const [searchInput, setSearchInput] = useState('')
  const [creditsFilter, setCreditsFilter] = useState(null)
  const [mediaFilter, setMediaFilter] = useState(null)
  const [topicFilter, setTopicFilter] = useState(null)
  const [filteredItemsCount, setFilteredItemsCount] = useState(null)
  const [firstItemForPage, setFirstItemForPage] = useState(0)
  const [lastItemForPage, setLastItemForPage] = useState(ITEMS_PER_PAGE)
  const hasPreviousCreditsFilter = getStorageString({
    key: StorageKey.CME_MEDIA_LIBRARY_CREDITS_FILTER,
    storageType: StorageType.SESSION,
  })
  const hasPreviousMediaFilter = getStorageString({
    key: StorageKey.CME_MEDIA_LIBRARY_MEDIA_FILTER,
    storageType: StorageType.SESSION,
  })
  const hasPreviousTopicFilter = getStorageString({
    key: StorageKey.CME_MEDIA_LIBRARY_TOPIC_FILTER,
    storageType: StorageType.SESSION,
  })
  useEffect(() => {
    if (hasPreviousCreditsFilter) {
      setCreditsFilter(hasPreviousCreditsFilter)
    }
    if (hasPreviousMediaFilter) {
      setMediaFilter(hasPreviousMediaFilter)
    }
    if (hasPreviousTopicFilter) {
      setTopicFilter(hasPreviousTopicFilter)
    }
  }, [getStorageString])

  useEffect(() => {
    if (!creditsFilter) {
      if (hasPreviousCreditsFilter) {
        removeStorageString({
          key: StorageKey.CME_MEDIA_LIBRARY_CREDITS_FILTER,
          storageType: StorageType.SESSION,
        })
      }
      return
    }
    setStorageString({
      key: StorageKey.CME_MEDIA_LIBRARY_CREDITS_FILTER,
      value: creditsFilter,
      storageType: StorageType.SESSION,
    })
  }, [creditsFilter])

  useEffect(() => {
    if (!mediaFilter) {
      if (hasPreviousMediaFilter) {
        removeStorageString({
          key: StorageKey.CME_MEDIA_LIBRARY_MEDIA_FILTER,
          storageType: StorageType.SESSION,
        })
      }
      return
    }
    setStorageString({
      key: StorageKey.CME_MEDIA_LIBRARY_MEDIA_FILTER,
      value: mediaFilter,
      storageType: StorageType.SESSION,
    })
  }, [mediaFilter])

  useEffect(() => {
    if (!topicFilter) {
      if (hasPreviousTopicFilter) {
        removeStorageString({
          key: StorageKey.CME_MEDIA_LIBRARY_TOPIC_FILTER,
          storageType: StorageType.SESSION,
        })
      }
      return
    }
    setStorageString({
      key: StorageKey.CME_MEDIA_LIBRARY_TOPIC_FILTER,
      value: topicFilter,
      storageType: StorageType.SESSION,
    })
  }, [topicFilter])

  useEffect(() => {
    if (search.credits) {
      setCreditsFilter(search.credits)
    }
    if (search.topic) {
      setTopicFilter(search.topic)
    }
    if (search.media) {
      setMediaFilter(search.media)
    }
  }, [search, credits, topics, types])

  const clearFilter = (whichFilter, e) => {
    e.preventDefault()
    if (whichFilter === creditsFilter) {
      setCreditsFilter(null)
    }
    if (whichFilter === mediaFilter) {
      setMediaFilter(null)
    } else if (whichFilter === topicFilter) {
      setTopicFilter(null)
    } else if (whichFilter === searchInput) {
      setSearchInput('')
    }
  }

  if (!doc || !types) {
    return null
  }
  const pageTitle = doc.title?.text ?? ''
  const bodyText = doc.body_text

  return (
    <>
      <RegionContainer>
        <Styled.HeroTitle>{pageTitle}</Styled.HeroTitle>
      </RegionContainer>
      <div className="two-column-layout" data-layout="two-column">
        <div className="two-column-layout__wrapper">
          <div className="two-column-layout__main">
            <RegionContainer>
              <Styled.HeroBody as="div">
                {bodyText?.richText && (
                  <PrismicRichText field={bodyText.richText} />
                )}
                {doc.cta_link && (
                  <CtaLink text={doc.cta_link_text} link={doc.cta_link} />
                )}
              </Styled.HeroBody>
            </RegionContainer>
            <RegionContainer>
              <Styled.MediaLibraryFeaturedTitle>
                Featured Story
              </Styled.MediaLibraryFeaturedTitle>
              <CmeMediaLibraryItem
                item={doc.highlight_media_item}
                isHighlightMediaItem={true}
              />
            </RegionContainer>
          </div>
          <div className="two-column-layout__aside">
            <div className="util-large-only">
              <CmeSidebar />
            </div>
          </div>
        </div>
      </div>
      <RegionContainer>
        <Styled.MediaLibrarySectionTitle>
          Media Library
        </Styled.MediaLibrarySectionTitle>
      </RegionContainer>
      <FilterRow>
        <Select
          placeholder="Credits"
          options={getMediaCreditsOptions(credits)}
          value={
            creditsFilter
              ? { value: creditsFilter, label: creditsFilter }
              : null
          }
          styles={DROPDOWN_STYLES}
          width={getMediaTypeOptions(types).length > 1 ? '180px' : '240px'}
          marginRight="10px"
          onChange={e =>
            e?.value ? setCreditsFilter(e.value) : setCreditsFilter(null)
          }
        />
        {getMediaTypeOptions(types).length > 1 && (
          <Select
            placeholder="Media"
            options={getMediaTypeOptions(types)}
            value={
              mediaFilter ? { value: mediaFilter, label: mediaFilter } : null
            }
            styles={DROPDOWN_STYLES}
            width={getMediaTypeOptions(types).length > 1 ? '180px' : '240px'}
            marginRight="10px"
            onChange={e =>
              e?.value ? setMediaFilter(e.value) : setMediaFilter(null)
            }
          />
        )}
        <Select
          placeholder="Topic"
          options={getMediaTopicOptions(topics)}
          value={
            topicFilter ? { value: topicFilter, label: topicFilter } : null
          }
          styles={DROPDOWN_STYLES}
          width={getMediaTypeOptions(types).length > 1 ? '180px' : '240px'}
          marginRight="10px"
          onChange={e =>
            e?.value ? setTopicFilter(e.value) : setTopicFilter(null)
          }
        />
        <form>
          <MediaLibrarySearchField
            placeholder="Search"
            onChange={e => setSearchInput(e.target.value)}
            value={searchInput}
            name="search"
          />
        </form>
      </FilterRow>

      <RegionContainer>
        <FilterIndicatorBar
          creditsFilter={creditsFilter}
          mediaFilter={mediaFilter}
          topicFilter={topicFilter}
          searchInput={searchInput}
          clearFilter={clearFilter}
        />
      </RegionContainer>

      <RegionContainer>
        <MediaLibraryItemsGrid
          creditsFilter={creditsFilter}
          mediaFilter={mediaFilter}
          topicFilter={topicFilter}
          searchInput={searchInput}
          mediaItemsList={mediaItemsList}
          setFilteredItemsCount={setFilteredItemsCount}
          firstItemForPage={firstItemForPage}
          lastItemForPage={lastItemForPage}
        />
        <PaginationControl
          itemCount={filteredItemsCount ? filteredItemsCount : 0}
          itemsPerPage={ITEMS_PER_PAGE}
          setFirstItemForPage={setFirstItemForPage}
          setLastItemForPage={setLastItemForPage}
          paginationChangeAction={paginationChangeAction}
        />
      </RegionContainer>

      <div className="util-small-only">
        <CmeSidebar />
      </div>
    </>
  )
}

CmeMediaLibrary.propTypes = {
  doc: object,
  credits: array,
  types: array,
  topics: array,
  search: object,
  mediaItemsList: array,
}

export default WithLocation(CmeMediaLibrary)
