import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import * as Styled from './styles/Sidebar.styles'

const CME_MEDIA_LIBRARY_QUERY = graphql`
  {
    prismicCmeMediaLibraryPage {
      data {
        sidebar_text {
          richText
        }
        sidebar_headline
      }
    }
  }
`

const CmeSidebar = () => (
  <StaticQuery
    query={`${CME_MEDIA_LIBRARY_QUERY}`}
    render={data => {
      const doc = data.prismicCmeMediaLibraryPage.data
      const heading = doc.sidebar_headline ?? ''
      const body = doc.sidebar_text
      return (
        <Styled.Sidebar $additionalStyles={Styled.CmeSidebarBody}>
          <Styled.SidebarGrid>
            <Styled.TextContainer>
              {heading && <Styled.Title>{heading}</Styled.Title>}
              {body?.richText && (
                <Styled.Body>
                  <PrismicRichText field={body.richText} />
                </Styled.Body>
              )}
            </Styled.TextContainer>
            <Styled.CircleAccentTop>
              <svg
                width="159"
                height="159"
                viewBox="0 0 159 159"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="79.5"
                  cy="79.5"
                  r="76.5"
                  stroke="#829FE8"
                  strokeWidth="6"
                />
              </svg>
            </Styled.CircleAccentTop>
            <Styled.CircleAccentBottom>
              <svg
                width="116"
                height="116"
                viewBox="0 0 116 116"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="58"
                  cy="58"
                  r="55"
                  stroke="#F29F05"
                  strokeWidth="6"
                />
              </svg>
            </Styled.CircleAccentBottom>
          </Styled.SidebarGrid>
        </Styled.Sidebar>
      )
    }}
  />
)

export default CmeSidebar
