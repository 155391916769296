import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import CmeMediaLibrary from '../components/CmeMediaLibrary'
import { BottomLineGoogleAd } from '../components/styles/GoogleAdsCommon.styles'
import GoogleAdBlock from '../components/ContentBlocks/GoogleAdBlock'
import { paginationChange } from '../util/helper'

const CmeMediaLibraryLandingPage = ({ data }) => {
  const doc = data.prismicCmeMediaLibraryPage.data
  const credits = data.allPrismicCmeMediaLibraryItemCredits.edges
  const types = data.allPrismicCmeMediaLibraryItemType.edges
  const topics = data.allPrismicCmeMediaLibraryItemTopic.edges
  const mediaItemsList = data.allPrismicCmeMediaLibraryItem.edges

  // console.log(`T: ${types}`)
  if (!doc || !types || !topics || !mediaItemsList) {
    return null
  }

  const twitterImage = doc.twitter_image?.url
    ? doc.twitter_image
    : doc.highlight_media_item.hero_image?.url
    ? doc.highlight_media_item.hero_image
    : null
  const facebookImage = doc.facebook_image?.url
    ? doc.facebook_image
    : doc.highlight_media_item.hero_image?.url
    ? doc.highlight_media_item.hero_image
    : null
  const seoTitle = doc.meta_title?.text
    ? doc.meta_title.text
    : 'Watch & Listen: For Health Care Providers'
  const seo_embed_code = doc.seo_embed_code?.text ?? null
  const sponsorshipText = doc.sidebar_sponsorship_text
    ? doc.sidebar_sponsorship_text
    : null

  // cme-events linkresolver workaround
  useEffect(() => {
    const isBrowser = typeof document !== 'undefined'
    if (isBrowser) {
      let bodyLinks = document.querySelectorAll('main a')
      if (bodyLinks) {
        bodyLinks.forEach(function (bodyLink) {
          let linkHref = bodyLink.getAttribute('href')
          if (linkHref === 'cme-events/') {
            bodyLink.setAttribute('href', '/' + linkHref)
          }
        })
      }
    }
  }, [])

  return (
    <>
      <SEO
        seo={{
          description: doc.meta_description,
          keywords: doc.meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
        }}
      />
      <Layout renderAnchor={false}>
        <CmeMediaLibrary
          doc={doc}
          credits={credits}
          types={types}
          topics={topics}
          mediaItemsList={mediaItemsList}
          paginationChangeAction={paginationChange}
          sponsorshipText={sponsorshipText}
        />
      </Layout>
    </>
  )
}

CmeMediaLibraryLandingPage.propTypes = {
  data: object,
}

export const query = graphql`
  {
    # prismicGeneralSiteSettings {
    #   data {
    #     media_library_landing_page_google_ad {
    #       document {
    #         ... on PrismicGoogleAdTag {
    #           id
    #         }
    #       }
    #     }
    #     media_library_item_interstitial_ad_tag {
    #       document {
    #         ... on PrismicGoogleAdTag {
    #           id
    #         }
    #       }
    #     }
    #   }
    # }
    allPrismicCmeMediaLibraryItemType(
      sort: { fields: data___type, order: ASC }
    ) {
      edges {
        node {
          id
          data {
            type
          }
        }
      }
    }
    allPrismicCmeMediaLibraryItemTopic(
      sort: { fields: data___topic, order: ASC }
    ) {
      edges {
        node {
          id
          data {
            topic
          }
        }
      }
    }
    allPrismicCmeMediaLibraryItemCredits(
      sort: { fields: data___credits, order: ASC }
    ) {
      edges {
        node {
          id
          data {
            credits
          }
        }
      }
    }
    allPrismicCmeMediaLibraryItem(
      sort: { fields: data___datestamp, order: DESC }
    ) {
      edges {
        node {
          id
          uid
          data {
            datestamp
            show_cme_tag
            title {
              richText
              text
            }
            body_text {
              text
              richText
            }
            hero_image {
              url
              alt
              thumbnails {
                library_hero_desktop {
                  url
                }
                library_hero_mobile {
                  url
                }
                library_thumbnail {
                  url
                }
              }
            }
            topic {
              id
              uid
              type
              link_type
              document {
                ... on PrismicCmeMediaLibraryItemTopic {
                  data {
                    topic
                  }
                }
              }
            }
            media_type {
              id
              uid
              type
              link_type
              document {
                ... on PrismicCmeMediaLibraryItemType {
                  data {
                    type
                  }
                }
              }
            }
            body {
              ... on PrismicCmeMediaLibraryItemDataBodyPodcast {
                id
                slice_type
                primary {
                  podcast_episode_date
                  bottom_padding_adjustment
                }
              }
              ... on PrismicCmeMediaLibraryItemDataBodyUpcomingWebinar {
                id
                slice_type
                primary {
                  event_time
                  bottom_padding_adjustment
                }
              }
              ... on PrismicCmeMediaLibraryItemDataBodyVideoBlock {
                id
                slice_type
                primary {
                  caption {
                    text
                  }
                  schema_publish_date
                  schema_video_description
                  video_duration_hours
                  video_duration_minutes
                  video_duration_seconds
                  video_link {
                    id
                    type
                  }
                  bitmovin_stream_id
                  transcription_link {
                    url
                    uid
                    type
                    link_type
                    id
                  }
                  bottom_padding_adjustment
                }
              }
            }
            credits {
              document {
                ... on PrismicCmeMediaLibraryItemCredits {
                  id
                  data {
                    credits
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicCmeMediaLibraryPage {
      id
      data {
        body_text {
          richText
        }
        title {
          text
          richText
        }
        sidebar_headline
        sidebar_text {
          text
          richText
        }
        facebook_image {
          url
        }
        meta_description
        meta_keywords
        meta_title {
          text
        }
        seo_embed_code {
          text
        }
        twitter_image {
          url
        }
        cta_link_text
        display_name
        cta_link {
          url
          uid
          type
          id
          link_type
        }
        highlight_media_item {
          id
          link_type
          uid
          document {
            ... on PrismicCmeMediaLibraryItem {
              id
              uid
              type
              data {
                title {
                  richText
                  text
                }
                body_text {
                  richText
                  text
                }
                hero_image {
                  alt
                  url
                  thumbnails {
                    mobile {
                      url
                    }
                    library_thumbnail {
                      url
                    }
                    library_hero_mobile {
                      url
                    }
                    library_hero_desktop {
                      url
                    }
                  }
                }
                show_cme_tag
                media_type {
                  document {
                    ... on PrismicCmeMediaLibraryItemType {
                      id
                      data {
                        type
                      }
                    }
                  }
                }
                topic {
                  document {
                    ... on PrismicCmeMediaLibraryItemTopic {
                      id
                      data {
                        topic
                      }
                    }
                  }
                }
                body {
                  ... on PrismicCmeMediaLibraryItemDataBodyPodcast {
                    id
                    slice_type
                    primary {
                      podcast_episode_date
                      bottom_padding_adjustment
                    }
                  }
                  ... on PrismicCmeMediaLibraryItemDataBodyUpcomingWebinar {
                    id
                    slice_type
                    primary {
                      event_time
                      bottom_padding_adjustment
                    }
                  }
                  ... on PrismicCmeMediaLibraryItemDataBodyVideoBlock {
                    id
                    slice_type
                    primary {
                      caption {
                        text
                      }
                      schema_publish_date
                      schema_video_description
                      video_duration_hours
                      video_duration_minutes
                      video_duration_seconds
                      video_link {
                        id
                        type
                      }
                      bitmovin_stream_id
                      transcription_link {
                        url
                        uid
                        type
                        link_type
                        id
                      }
                      bottom_padding_adjustment
                    }
                  }
                }
                datestamp
                credits {
                  document {
                    ... on PrismicCmeMediaLibraryItemCredits {
                      id
                      data {
                        credits
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default CmeMediaLibraryLandingPage
